<template>
	<div class="button-holder">
		<div class="contain-pictures">
			<PreviewImagesThumb
				hide-crop-option
				:loading="isImgUploadLoading"
				:images="imagesListMap"
				@delete-picture="handleDeletePicture"
				@add-pic-click="takePicture"
			/>
		</div>
	</div>
</template>

<script>
import { isNil } from '@/shared/utils';
import { store } from '@/store';
import { xidToImgObj } from '@/components/Packages/Captures/capture.utils';
import { Camera, CameraResultType } from '@capacitor/camera';
import PreviewImagesThumb from '@/components/Packages/FIlesPreviewer/components/PreviewImagesThumb';
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'GetPictureFromCameraPwaBtnComponent',
	components: { PreviewImagesThumb },
	mixins: [languageMessages],
	props: {
		block: {
			type: Boolean,
			require: false,
			default: false
		},
		customClass: {
			type: String,
			require: false,
			default: ''
		},
		label: {
			type: String,
			require: false,
			default: null
		}
	},
	data() {
		return {
			imagesList: [],
			isImgUploadLoading: false,
			takenImageUrl: null
		};
	},
	computed: {
		/**
		 * @return {String}
		 */
		rendLabel() {
			return isNil(this.label) ? this.FormMSG(87986, 'Take Picture') : this.label;
		},

		/**
		 * @return {Array}
		 */
		imagesListMap() {
			const _i = this.imagesList;
			const __p = Array.isArray(_i) ? _i : [_i];
			return __p.map((xid) => xidToImgObj(xid));
		}
	},
	watch: {
		imagesList: {
			/**
			 * @param {Array} list
			 */
			handler(list) {
				this.$emit('input', list);
			},
			deep: true
		}
	},
	methods: {
		handleCloseModal() {
			this.isModalOpen = false;
		},

		// async takePicture() {
		// 	const cameraPhoto = await Camera.getPhoto({
		// 		quality: 100,
		// 		allowEditing: true,
		// 		resultType: CameraResultType.Uri
		// 	})

		// 	const fileName = new Date().getTime() + '.jpeg'
		// 	const savedFileImage = {
		// 		filePath: fileName,
		// 		webviewPath: cameraPhoto.webPath
		// 	}
		// 	this.imagesList.value = [savedFileImage, ...photos.value]

		// 	// image.webPath will contain a path that can be set as an image src.
		// 	// You can access the original file using image.path, which can be
		// 	// passed to the Filesystem API to read the raw data of the image,
		// 	// if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
		// 	// var imageUrl = image.webPath

		// 	// // Can be set to the src of an image now
		// 	// imageElement.src = imageUrl
		// }
		async takePicture() {
			this.isImgUploadLoading = true;
			const photo = await Camera.getPhoto({
				resultType: CameraResultType.Uri,
				allowEditing: true,
				quality: 100
			});

			const blob = await fetch(photo.webPath).then((r) => r.blob());

			let formData = new FormData();
			formData.append('uploadimage', blob);

			// const { parentType, parentSubType, parentId } = this.captureOptions
			// if (!isNil(parentType)) formData.append('parentType', parentType)
			// if (!isNil(parentSubType)) formData.append('parentSubType', parentSubType)
			// if (!isNil(parentId)) formData.append('parentId', parentId)

			try {
				const savedImg = await this.$axios.$post('upload', formData, {
					headers: {
						Authorization: `Bearer ${store.state.token}`,
						'content-type': 'multipart/form-data'
					}
				});
				this.imagesList.push(savedImg);
				this.isImgUploadLoading = false;
			} catch (e) {
				console.error({ e });
			}
		},

		handleDeletePicture() {
			this.imagesList.find((obj) => {
				// Returns the object where
				// the given property has some value
				return obj.id === 1;
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.button-holder {
	width: 100%;

	button {
		margin-bottom: 20px;
	}
}

.contain-pictures {
	display: flex;
}
</style>
