import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

const takePicture = async () => {
	const image = await Camera.getPhoto({
		quality: 90,
		allowEditing: true,
		resultType: CameraResultType.Uri
	});
	const base64Data = await readAsBase64(image);
	return base64Data;
};

const pictureSourceCamera = async () => {
	const image = await Camera.getPhoto({
		quality: 90,
		allowEditing: true,
		source: CameraSource.Camera,
		resultType: CameraResultType.Uri
	});
	return image.webPath;
};

const pictureSourcePhotos = async () => {
	const image = await Camera.getPhoto({
		quality: 90,
		allowEditing: true,
		source: CameraSource.Photos,
		resultType: CameraResultType.Uri
	});
	return image.webPath;
};

const readAsBase64 = async (image) => {
	// Fetch the photo, read as a blob, then convert to base64 format
	const response = await fetch(image);
	const blob = await response.blob();

	return await convertBlobToBase64(blob);
};

const convertBlobToBase64 = (blob) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onerror = reject;
		reader.onload = () => resolve(reader.result);
		reader.readAsDataURL(blob);
	});
};

export default {
	takePicture,
	pictureSourcePhotos,
	pictureSourceCamera,
  readAsBase64,
};
