var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "my-expense-mobile" }, [
    _c(
      "div",
      {
        staticClass: "cursor-pointer title",
        on: {
          click: function ($event) {
            _vm.action === 1 ? _vm.goForce() : ""
          },
        },
      },
      [
        _c(
          "h1",
          { staticClass: "main-page-title", class: { "is-pwa": _vm.$isPwa() } },
          [
            _vm.action === 1
              ? _c(
                  "span",
                  [_c("arrow-left", { attrs: { color: "#FFFFFF", size: 26 } })],
                  1
                )
              : _vm._e(),
            _vm._v(" " + _vm._s(_vm.captionTitle) + "\n\t\t"),
          ]
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "form", class: { "mt-16": _vm.$isPwa() } },
      [
        _c(
          "b-form",
          { on: { submit: _vm.submitForm } },
          [
            _c(
              "div",
              { staticClass: "d-flex justify-content-start sub-title" },
              [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.FormMSG(3, "General Information")) +
                    "\n\t\t\t"
                ),
              ]
            ),
            _vm.expense.comment && _vm.expense.comment.length > 0
              ? _c("div", { staticClass: "expense-comment" }, [
                  _c("p", { staticClass: "px-2" }, [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.expense.comment) +
                        "\n\t\t\t\t"
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.useComponent
              ? _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: _vm.FormMSG(76, "Select Person"),
                      "label-for": "new-expense_ticket-ref",
                    },
                  },
                  [
                    _c("b-form-select", {
                      attrs: {
                        size: "md",
                        options: _vm.users,
                        "value-field": "value",
                        "text-field": "name",
                        disabled: _vm.isUpdate,
                      },
                      model: {
                        value: _vm.selectedUser,
                        callback: function ($$v) {
                          _vm.selectedUser = $$v
                        },
                        expression: "selectedUser",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "mt-3" },
              [
                _c("label", { attrs: { for: "date-input" } }, [
                  _vm._v(_vm._s(_vm.FormMSG(4, "Choose a date"))),
                ]),
                _c(
                  "b-input-group",
                  [
                    _c("b-form-input", {
                      attrs: { id: "date-input", type: "text", readonly: "" },
                      on: { click: _vm.handleDateInput },
                      model: {
                        value: _vm.dateFormatted,
                        callback: function ($$v) {
                          _vm.dateFormatted = $$v
                        },
                        expression: "dateFormatted",
                      },
                    }),
                    _c(
                      "b-input-group-append",
                      [
                        _c("b-form-datepicker", {
                          attrs: {
                            id: "mileage-date-picker",
                            "button-only": "",
                            right: "",
                            locale: _vm.currentLang,
                            "aria-controls": "date-input",
                            "close-button": "",
                          },
                          on: { context: _vm.onContext },
                          model: {
                            value: _vm.expense.date,
                            callback: function ($$v) {
                              _vm.$set(_vm.expense, "date", $$v)
                            },
                            expression: "expense.date",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                staticClass: "mt-3",
                attrs: {
                  label: _vm.FormMSG(5, "Description"),
                  "label-for": "expense-description",
                  "label-cols": 12,
                },
              },
              [
                _c("b-form-textarea", {
                  class: { "is-invalid": _vm.$v.expense.description.$error },
                  attrs: { id: "expense-description", rows: "3" },
                  model: {
                    value: _vm.expense.description,
                    callback: function ($$v) {
                      _vm.$set(_vm.expense, "description", $$v)
                    },
                    expression: "expense.description",
                  },
                }),
                _vm.$v.expense.description.$error
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(
                            _vm.FormMSG(14, "Please, length should be at least")
                          ) +
                          " " +
                          _vm._s(_vm.minLengthDescription) +
                          "\n\t\t\t\t"
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c("div", { staticClass: "divider pt-3" }),
            _c("div", { staticClass: "sub-title pb-4" }, [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(_vm.FormMSG(6, "Travel Details")) +
                  "\n\t\t\t"
              ),
            ]),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12" } },
                  [
                    _c("transport-type-selector", {
                      ref: "transportTypeRef",
                      attrs: {
                        "data-additional-detail": _vm.expense.additionalSubType,
                        "data-detail": _vm.expense.subType,
                        "data-type": _vm.expense.subCategory,
                        "hide-boat-type": "",
                        "hide-freight-type": "",
                        "hide-menues-not-two-wheels": "",
                        "hide-special-vehicles": "",
                      },
                      on: {
                        "transportation-type-selector:change":
                          _vm.handleTransportationTypeSelectorChange,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "pt-2" },
              [
                _c("google-distance", {
                  attrs: {
                    "distance-valid": _vm.$v.expense.km.$model > 0,
                    "edit-data": _vm.expense,
                    "to-location-label": _vm.FormMSG(9, "Arrival point"),
                    "from-location-label": _vm.FormMSG(10, "Starting point"),
                    "to-location-error-message": _vm.FormMSG(
                      17,
                      "Arrival point, is required"
                    ),
                    "from-location-error-message": _vm.FormMSG(
                      18,
                      "Starting point, is required"
                    ),
                    "inline-validator": true,
                    "is-submitted": _vm.isSubmitted,
                  },
                  on: {
                    "google-distance:selector-from:invalid":
                      _vm.isInvalidGoogleFrom,
                    "google-distance:selector-to:invalid":
                      _vm.isInvalidGoogleTo,
                    "google-distance:loading": _vm.calculateLoading,
                    change: _vm.handleDistanceChanges,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "pt-2" },
              [
                _c("department-selection", {
                  attrs: {
                    "edit-data": _vm.expense,
                    "hide-transportation-mean": "",
                    "is-new": _vm.isNew,
                    "category-for-transportation": "",
                    "remove-dep-zero": "",
                    isSubmitted: _vm.isSubmitted,
                    "for-expense": "",
                  },
                  on: {
                    change: _vm.handleDepartmentAndCatChange,
                    "department:selector:invalid": _vm.isInvalidateFields,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "pt-2 pb-0",
                class: `${
                  _vm.action === 0
                    ? "supplier-pb-with-cancel"
                    : "supplier-pb-without-cancel"
                }`,
              },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: _vm.FormMSG(11, "Travel distance"),
                      "label-for": "expense-travel-distance",
                    },
                  },
                  [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-input", {
                          class: { "is-invalid": _vm.$v.expense.km.$error },
                          attrs: {
                            id: "expense-travel-distance",
                            type: "number",
                            placeholder: "0",
                            min: "0",
                            step: "0.001",
                          },
                          on: { change: _vm.handleCategoryChange },
                          model: {
                            value: _vm.$v.expense.km.$model,
                            callback: function ($$v) {
                              _vm.$set(_vm.$v.expense.km, "$model", $$v)
                            },
                            expression: "$v.expense.km.$model",
                          },
                        }),
                        _c(
                          "b-input-group-append",
                          [
                            _c("b-input-group-text", [
                              _c(
                                "span",
                                {
                                  staticClass: "font-weight-bold",
                                  staticStyle: { color: "#06263e" },
                                },
                                [_vm._v(_vm._s(_vm.distanceUnit))]
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm.$v.expense.km.$error
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.FormMSG(16, "Please, enter the mileage")
                                  ) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "info-message",
                        attrs: { id: "tooltip-distance" },
                        on: { click: _vm.openInfoDistance },
                      },
                      [
                        _c("span", [_c("info", { attrs: { size: 16 } })], 1),
                        _c("span", { staticClass: "label" }, [
                          _vm._v(_vm._s(_vm.FormMSG(26, "Info message"))),
                        ]),
                      ]
                    ),
                    _c(
                      "b-popover",
                      {
                        attrs: {
                          target: "tooltip-distance",
                          show: _vm.popInfoDistance,
                          placement: "bottom",
                        },
                        on: {
                          "update:show": function ($event) {
                            _vm.popInfoDistance = $event
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.FormMSG(
                                27,
                                "You can always change this unit in your project configuration"
                              )
                            ) +
                            " (" +
                            _vm._s(_vm.distanceUnit) +
                            ")\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "grid-project-resume my-4" }, [
                  _c("div", { staticClass: "block-resume" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v(
                        _vm._s(_vm.FormMSG(12, "Carbon Footprint")) + " (kg CO"
                      ),
                      _c("sub", [_vm._v("2")]),
                      _vm._v(")"),
                    ]),
                    _c("div", { staticClass: "amount" }, [
                      _vm._v(_vm._s(_vm.convertToKgCo2(_vm.expense.kgCoTwo))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "icon" },
                      [_c("sprout", { attrs: { color: "#00A09C", size: 14 } })],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "block-resume none-border" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v(_vm._s(_vm.FormMSG(24, "Total amount"))),
                    ]),
                    _c("div", { staticClass: "amount" }, [
                      _vm._v(
                        _vm._s(_vm.convertToCurrency(_vm.expense.amountTotal))
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "icon" },
                      [
                        _c("currency-svg", {
                          attrs: {
                            color: "#00A09C",
                            opacity: "0.85",
                            width: "12",
                            height: "12",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  [
                    _c(
                      "b-alert",
                      {
                        attrs: {
                          show: _vm.showAlertChangeTransportMean,
                          variant: "warning",
                          dismissible: "",
                        },
                        on: {
                          dismissed: function ($event) {
                            _vm.showAlertChangeTransportMean = false
                          },
                        },
                      },
                      [
                        _c(_vm.getLucideIcon("AlertTriangle"), {
                          tag: "component",
                          attrs: { size: 16 },
                        }),
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.FormMSG(
                                485,
                                "The information of transport type has changed with vehicle information, please verify"
                              )
                            ) +
                            "\n\t\t\t\t\t"
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-row",
              { staticStyle: { "padding-bottom": "100px" } },
              [
                _c("b-col", [
                  _c(
                    "fieldset",
                    {
                      staticClass: "my-0 py-0 pb-0",
                      class: `${
                        _vm.$screen.width <= 576
                          ? "card-inside"
                          : "scheduler-border"
                      }`,
                    },
                    [
                      _c(
                        "legend",
                        {
                          class: `${
                            _vm.$screen.width <= 576
                              ? "card-inside"
                              : "scheduler-border"
                          } ${
                            _vm.$screen.width > 576
                              ? "text-color-rhapsody-in-blue-2"
                              : ""
                          }`,
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(168, "Green details")) +
                              "\n\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "pb-3" },
                        [
                          _c("co-two-clap", {
                            ref: "coTwoClapTicket",
                            attrs: {
                              "parent-id": +_vm.expense.id,
                              "entity-type": 1,
                              "for-create": false,
                              "external-carbon-data": _vm.carbonData,
                              "hide-location-menu": "",
                              "hide-premise-menu": "",
                              "hide-btn-reviewed": "",
                              "disable-carbon-type-selector": "",
                              "hide-distance-known-transport": "",
                              "hide-transport-type-selector-transport": "",
                              "hide-from-address-transport": "",
                              "hide-to-address-transport": "",
                              "hide-distance-transport": "",
                              "get-my-vehicule-in-transport": "",
                            },
                            on: {
                              "co-two-clap:change": _vm.handleCoTwoClapChange,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "footer-fixed" },
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "mb-2",
                        attrs: {
                          block: "",
                          size: "lg",
                          type: "submit",
                          variant: "primary",
                          disabled:
                            _vm.loading || _vm.isInvalid || _vm.$v.$invalid,
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(13, "Save")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm.action === 0
                  ? _c(
                      "b-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mb-2",
                            attrs: {
                              block: "",
                              size: "lg",
                              type: "button",
                              variant: "light",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleCancel(_vm.expense.id)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(19, "Cancel")) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }