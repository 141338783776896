var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "button-holder" }, [
    _c(
      "div",
      { staticClass: "contain-pictures" },
      [
        _c("PreviewImagesThumb", {
          attrs: {
            "hide-crop-option": "",
            loading: _vm.isImgUploadLoading,
            images: _vm.imagesListMap,
          },
          on: {
            "delete-picture": _vm.handleDeletePicture,
            "add-pic-click": _vm.takePicture,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }