var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$screen.width <= 576
    ? _c("div", { staticClass: "animated fadeIn" }, [
        _c(
          "div",
          { staticClass: "container-mobile" },
          [
            _c(
              "div",
              [
                _c(
                  "b-alert",
                  {
                    attrs: {
                      show:
                        _vm.error && _vm.error.status === 509 ? true : false,
                      variant: "warning",
                    },
                  },
                  [
                    _c("h4", { staticClass: "alert-heading" }, [
                      _vm._v(_vm._s(_vm.FormMSG(1, "Informations"))),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row justify-content-start align-items-center w-100",
                        staticStyle: { gap: "8px" },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(_vm.getLucideIcon("AlertTriangle"), {
                              tag: "component",
                              attrs: { color: "#EA4E", size: 40 },
                            }),
                          ],
                          1
                        ),
                        _c("div", [_vm._v(_vm._s(_vm.error.message))]),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm.form == 2
              ? _c("ticket-form", {
                  attrs: {
                    useComponent: this.$props.useComponent,
                    action: _vm.action,
                    users: _vm.users,
                    "edit-data": _vm.expense,
                  },
                  on: { "ticket-form-mobile:submitted": _vm.handleSubmit },
                })
              : _vm._e(),
            _vm.form == 3
              ? _c("invoice-form", {
                  attrs: {
                    users: _vm.users,
                    action: _vm.action,
                    useComponent: this.$props.useComponent,
                  },
                })
              : _vm._e(),
            _vm.form == 1
              ? _c("mileage-form", {
                  attrs: {
                    users: _vm.users,
                    action: _vm.action,
                    "edit-data": _vm.expense,
                    useComponent: this.$props.useComponent,
                  },
                  on: { "mileage-form-mobile:submitted": _vm.handleSubmit },
                })
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }