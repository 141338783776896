<template>
	<div class="my-expense-mobile">
		<div class="cursor-pointer title" @click="action === 1 ? goForce() : ''">
			<h1 class="main-page-title" :class="{ 'is-pwa': $isPwa() }">
				<span v-if="action === 1"><arrow-left color="#FFFFFF" :size="26" /></span> {{ captionTitle }}
			</h1>
		</div>
		<div class="form" :class="{ 'mt-16': $isPwa() }">
			<b-form @submit="submitForm">
				<div class="d-flex justify-content-start sub-title">
					{{ FormMSG(3, 'General Information') }}
				</div>
				<div class="expense-comment" v-if="expense.comment && expense.comment.length > 0">
					<p class="px-2">
						{{ expense.comment }}
					</p>
				</div>
				<b-form-group :label="FormMSG(76, 'Select Person')" label-for="new-expense_ticket-ref" v-if="useComponent">
					<b-form-select size="md" v-model="selectedUser" :options="users" value-field="value" text-field="name" :disabled="isUpdate"></b-form-select>
				</b-form-group>
				<div class="mt-3">
					<label for="date-input">{{ FormMSG(4, 'Choose a date') }}</label>
					<b-input-group>
						<b-form-input id="date-input" v-model="dateFormatted" type="text" readonly @click="handleDateInput"></b-form-input>
						<b-input-group-append>
							<b-form-datepicker
								id="mileage-date-picker"
								v-model="expense.date"
								button-only
								right
								:locale="currentLang"
								aria-controls="date-input"
								@context="onContext"
								close-button
							></b-form-datepicker>
						</b-input-group-append>
					</b-input-group>
				</div>
				<b-form-group :label="FormMSG(5, 'Description')" label-for="expense-description" :label-cols="12" class="mt-3">
					<b-form-textarea id="expense-description" v-model="expense.description" rows="3" :class="{ 'is-invalid': $v.expense.description.$error }" />
					<div v-if="$v.expense.description.$error" class="invalid-feedback">
						{{ FormMSG(14, 'Please, length should be at least') }}&nbsp;{{ minLengthDescription }}
					</div>
				</b-form-group>
				<div class="divider pt-3"></div>
				<div class="sub-title pb-4">
					{{ FormMSG(6, 'Travel Details') }}
				</div>
				<b-row>
					<b-col cols="12">
						<transport-type-selector
							ref="transportTypeRef"
							:data-additional-detail="expense.additionalSubType"
							:data-detail="expense.subType"
							:data-type="expense.subCategory"
							hide-boat-type
							hide-freight-type
							hide-menues-not-two-wheels
							hide-special-vehicles
							@transportation-type-selector:change="handleTransportationTypeSelectorChange"
						/>
					</b-col>
				</b-row>
				<div class="pt-2">
					<google-distance
						:distance-valid="$v.expense.km.$model > 0"
						:edit-data="expense"
						:to-location-label="FormMSG(9, 'Arrival point')"
						:from-location-label="FormMSG(10, 'Starting point')"
						:to-location-error-message="FormMSG(17, 'Arrival point, is required')"
						:from-location-error-message="FormMSG(18, 'Starting point, is required')"
						:inline-validator="true"
						:is-submitted="isSubmitted"
						@google-distance:selector-from:invalid="isInvalidGoogleFrom"
						@google-distance:selector-to:invalid="isInvalidGoogleTo"
						@google-distance:loading="calculateLoading"
						@change="handleDistanceChanges"
					/>
				</div>
				<div class="pt-2">
					<department-selection
						:edit-data="expense"
						hide-transportation-mean
						:is-new="isNew"
						category-for-transportation
						remove-dep-zero
						:isSubmitted="isSubmitted"
						@change="handleDepartmentAndCatChange"
						@department:selector:invalid="isInvalidateFields"
						for-expense
					/>
				</div>
				<div class="pt-2 pb-0" :class="`${action === 0 ? 'supplier-pb-with-cancel' : 'supplier-pb-without-cancel'}`">
					<b-form-group :label="FormMSG(11, 'Travel distance')" label-for="expense-travel-distance">
						<b-input-group>
							<b-form-input
								id="expense-travel-distance"
								type="number"
								placeholder="0"
								min="0"
								step="0.001"
								v-model="$v.expense.km.$model"
								@change="handleCategoryChange"
								:class="{ 'is-invalid': $v.expense.km.$error }"
							/>
							<b-input-group-append>
								<b-input-group-text>
									<span class="font-weight-bold" style="color: #06263e">{{ distanceUnit }}</span>
								</b-input-group-text>
							</b-input-group-append>
							<div v-if="$v.expense.km.$error" class="invalid-feedback">
								{{ FormMSG(16, 'Please, enter the mileage') }}
							</div>
						</b-input-group>
						<div class="info-message" id="tooltip-distance" @click="openInfoDistance">
							<span><info :size="16" /></span>
							<span class="label">{{ FormMSG(26, 'Info message') }}</span>
						</div>
						<b-popover target="tooltip-distance" :show.sync="popInfoDistance" placement="bottom">
							{{ FormMSG(27, 'You can always change this unit in your project configuration') }} ({{ distanceUnit }})
						</b-popover>
					</b-form-group>
					<div class="grid-project-resume my-4">
						<div class="block-resume">
							<div class="label">{{ FormMSG(12, 'Carbon Footprint') }} (kg CO<sub>2</sub>)</div>
							<div class="amount">{{ convertToKgCo2(expense.kgCoTwo) }}</div>
							<div class="icon"><sprout color="#00A09C" :size="14" /></div>
						</div>
						<div class="block-resume none-border">
							<div class="label">{{ FormMSG(24, 'Total amount') }}</div>
							<div class="amount">{{ convertToCurrency(expense.amountTotal) }}</div>
							<div class="icon"><currency-svg color="#00A09C" opacity="0.85" width="12" height="12" /></div>
						</div>
					</div>
				</div>
				<b-row>
					<b-col>
						<b-alert :show="showAlertChangeTransportMean" variant="warning" dismissible @dismissed="showAlertChangeTransportMean = false">
							<component :is="getLucideIcon('AlertTriangle')" :size="16" />
							{{ FormMSG(485, 'The information of transport type has changed with vehicle information, please verify') }}
						</b-alert>
					</b-col>
				</b-row>
				<b-row style="padding-bottom: 100px">
					<b-col>
						<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
							<legend
								:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'} ${
									$screen.width > 576 ? 'text-color-rhapsody-in-blue-2' : ''
								}`"
							>
								{{ FormMSG(168, 'Green details') }}
							</legend>
							<div class="pb-3">
								<co-two-clap
									ref="coTwoClapTicket"
									:parent-id="+expense.id"
									:entity-type="1"
									:for-create="false"
									:external-carbon-data="carbonData"
									hide-location-menu
									hide-premise-menu
									hide-btn-reviewed
									disable-carbon-type-selector
									hide-distance-known-transport
									hide-transport-type-selector-transport
									hide-from-address-transport
									hide-to-address-transport
									hide-distance-transport
									get-my-vehicule-in-transport
									@co-two-clap:change="handleCoTwoClapChange"
								/>
							</div>
						</fieldset>
					</b-col>
				</b-row>
				<b-row class="footer-fixed">
					<b-col cols="12">
						<b-button block size="lg" type="submit" variant="primary" class="mb-2" :disabled="loading || isInvalid || $v.$invalid">
							{{ FormMSG(13, 'Save') }}
						</b-button>
					</b-col>
					<b-col cols="12" v-if="action === 0">
						<b-button block size="lg" type="button" variant="light" class="mb-2" @click="handleCancel(expense.id)">
							{{ FormMSG(19, 'Cancel') }}
						</b-button>
					</b-col>
				</b-row>
			</b-form>
		</div>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import languageMessages from '@/mixins/languageMessages';
import { validationMixin } from 'vuelidate';
import { required, decimal, minLength } from 'vuelidate/lib/validators';
import GoogleDistance from '@/components/GoogleDistance';
import { ArrowLeft, Sprout, Euro, Info } from 'lucide-vue';
import { objReducer, isNil, greaterThanZero } from '@/shared/utils';
import { GetKmGreenImpact } from '@/cruds/carbon.crud';
import { rendCurrency, rendKgCo2 } from '~helpers';
import CurrencySvg from '@/components/Svg/Currency';
import { store } from '@/store';
import { recalculateDistanceValueByUnit, recalculateDistanceValueToKm } from '@/shared/google/helpers';
import DepartmentSelection from '@/components/DepartmentsSelection';
import CoTwoClap from '@/modules/carbonclap/components/green/CoTwoClap';
import { INIT_DATA_CARBON } from '@/shared/constants';
import TransportTypeSelector from '@/modules/transports/components/TransportTypeSelector.vue';
import _ from 'lodash';
import { getVehicule } from '../../cruds/vehicules.crud';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'MileageForm',
	mixins: [languageMessages, validationMixin, globalMixin],
	components: {
		ArrowLeft,
		GoogleDistance,
		Sprout,
		Euro,
		CurrencySvg,
		Info,
		DepartmentSelection,
		CoTwoClap,
		TransportTypeSelector
	},
	props: {
		useComponent: {
			type: Boolean,
			required: false,
			default: false
		},
		action: {
			type: Number, // ADD = 0, UPDATE = 1
			required: true,
			default: 0
		},
		users: {
			type: Array,
			required: true
		},
		editData: {
			type: Object,
			required: false,
			default: null
		}
	},
	computed: {
		captionTitle() {
			return this.action === 0 ? this.FormMSG(1, 'Add Mileage') : this.FormMSG(2, 'Update Mileage');
		},
		optionsForTransportMean() {
			let transports = this.FormMenu(905);
			transports = transports.map((t) => ({
				value: t.value,
				text: t.text
			}));

			const text = this.FormMSG(8, 'Select a transportion');

			transports = transports.sort(function compare(a, b) {
				if (a.text.toLowerCase() < b.text.toLowerCase()) return -1;
				if (a.text.toLowerCase() > b.text.toLowerCase()) return 1;
				return 0;
			});

			return [{ value: null, text }, ...transports];
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit;
		}
	},
	data() {
		return {
			isMobile: false,
			dateFormatted: '',
			expense: {
				subCategory: 6,
				subType: 0,
				additionalSubType: 0
			},
			isSubmitted: false,
			minLengthDescription: 4,
			isInvalid: false,
			dontRecalculate: false,
			incRecalculate: 0,
			loading: false,
			popInfoDistance: false,
			selectedUser: 0,
			isNew: false,
			isUpdate: false,
			submitCarbon: false,
			carbonData: _.cloneDeep(INIT_DATA_CARBON),
			carbonDataInChange: {
				reason: 0,
				numberOfVehicule: 1,
				vehiculeId: 0,
				number: 0,
				type: 0,
				subType: 0,
				additionalSubType: 0
			},
			showAlertChangeTransportMean: false,
			invalidGoogleFrom: false,
			invalidGoogleTo: false
		};
	},
	created() {
		this.$nextTick(() => {
			const { action, expenseId } = this.$route.params;
			if (action === 'n') {
				this.isNew = true;
				this.selectedUser = parseInt(store.state.myProfile.id, 10);
				this.isUpdate = false;
			} else if (action === 'u') {
				this.isNew = false;
				this.selectedUser = parseInt(this.expense.user.id, 10);
				this.isUpdate = true;
			}
			if (isNil(this.expense) || (this.expense && this.expense.id !== expenseId && action === 'u')) {
				const path = '/myexpenses';
				this.$router.push({ path });
			}
		});
	},
	mounted() {
		this.$refs.transportTypeRef.isInvalid();
		this.$v.$touch();
	},
	methods: {
		handleTransportationTypeSelectorChange({ type, detail, additionalDetail }) {
			this.$v.$touch();
			this.expense.subCategory = type;
			this.expense.subType = detail === null ? 0 : detail;
			this.expense.additionalSubType = additionalDetail === null ? 0 : additionalDetail;

			this.carbonDataInChange.type = type;
			this.carbonDataInChange.subType = detail === null ? 0 : detail;
			this.carbonDataInChange.additionalSubType = additionalDetail === null ? 0 : additionalDetail;
		},
		async handleCoTwoClapChange({ carbonData }) {
			this.carbonDataInChange.reason = carbonData.reason;
			this.carbonDataInChange.numberOfVehicule = carbonData.numberOfVehicule;
			this.carbonDataInChange.vehiculeId = carbonData.vehiculeId;
			this.carbonDataInChange.number = carbonData.number;

			if (carbonData.vehiculeId > 0) {
				const resultVehicule = await getVehicule(carbonData.vehiculeId);

				this.expense.subCategory = resultVehicule.type;
				this.expense.subType = resultVehicule.subType;
				this.expense.additionalSubType = resultVehicule.additionalSubType;

				this.showAlertChangeTransportMean = true;

				window.scrollTo({ top: 0, behavior: 'smooth' });
			}
		},
		handleCoTwoClapEndSubmit() {
			this.$emit('mileage-form-mobile:submitted', {
				type: 2,
				success: true
			});
			this.$router.go(-1);
		},
		handleDepartmentAndCatChange({ category, department }) {
			this.expense = {
				...this.expense,
				category,
				department
			};
		},
		isInvalidateFields(payload) {
			this.isInvalid = payload;
			this.isSubmitted = true;
			this.$emit('mileage:invalid', this.isInvalid);
		},
		...mapActions({
			setNewExpense: 'expenses/setNewExpense',
			deleteExpense: 'expenses/deleteExpense'
		}),
		goBack(success = null) {
			const { back, id } = this.$route.query;
			let path = '/myexpenses';

			if (!isNil(back)) {
				if (back === 'e') {
					path += `/${id}`;
					if (!isNil(success) && typeof success === 'number') {
						path += `?s=${success}`;
					}
				}
			} else if (!isNil(success) && typeof success === 'number') {
				path += `?s=${success}`;
			}

			this.$router.push({ path }).catch(() => {});
		},
		goForce() {
			let path = '/myexpenses';
			this.$router.push({ path });
		},
		async submitForm(e) {
			e.preventDefault();
			this.$v.$touch();
			const invalidTypeSelector = this.$refs.transportTypeRef.isInvalid();

			if (invalidTypeSelector === false) {
				this.isSubmitted = true;

				if (this.$v.$invalid || this.isInvalid) {
					return;
				}

				if (new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}$', 'g').test(this.expense.date)) {
					this.expense.date += 'T00:00:00Z';
				}

				this.expense = {
					...this.expense,
					km: recalculateDistanceValueToKm(this.expense.km)
				};

				if (this.expense.validated === 4 || this.expense.validated === 16) {
					this.$bvModal
						.msgBoxConfirm(this.FormMSG(25, 'Are you sure to continue with this change ?'), {
							title: this.FormMSG(21, 'Confirm'),
							size: 'sm',
							buttonSize: 'sm',
							okVariant: 'danger',
							okTitle: this.FormMSG(22, 'YES'),
							cancelTitle: this.FormMSG(23, 'NO'),
							footerClass: 'p-2',
							hideHeaderClose: false,
							centered: false
						})
						.then(async (v) => {
							if (!v) return;

							this.expense.validated = 0;
							await this.updateExpense();
						})
						.catch((err) => console.log(err));
				} else {
					await this.updateExpense();
				}
			}
		},
		async updateExpense() {
			this.carbonData.km = this.expense.km;
			this.carbonData = {
				...this.carbonData,
				...this.carbonDataInChange,
				carbonId: +this.carbonData.id
			};
			await this.setNewExpense({
				id: +this.expense.id,
				expense: {
					...this.expense,
					carbon: {
						...this.carbonData
					}
				},
				userId: +this.selectedUser
			})
				.then(() => {
					this.$emit('mileage-form-mobile:submitted', {
						type: 2,
						action: this.action,
						success: true
					});

					this.$router.push(`/myexpenses?type=2&s=${this.action === 0 ? '0' : '1'}`);
				})
				.catch((e) => {
					this.errorRedirect(e);
				});
		},
		errorRedirect(e) {
			let path = `/myexpenses`;
			const error = e.message.match(new RegExp('[0-9]{3}', 'g'));
			let code = '0';

			if (!isNil(error) && error.length === 1) {
				code = error[0];
			}

			path += `?ec=${code}&eu=1`;
			this.$router.push({ path });
		},
		onContext(ctx) {
			this.dateFormatted = ctx.selectedFormatted;
		},
		handleCategoryChange() {
			this.$v.$touch();
			this.updateKgCO();
		},
		async updateKgCO() {
			if (recalculateDistanceValueToKm(this.expense.km) > 0) {
				this.expense.kgCoTwo = await GetKmGreenImpact(
					recalculateDistanceValueToKm(this.expense.km),
					this.expense.subCategory,
					this.expenseItem.subType
				);
			} else {
				this.expense.kgCoTwo = 0;
			}
		},
		handleDistanceChanges(payload) {
			this.$v.$touch();
			this.expense = {
				...this.expense,
				...objReducer(['fromLocation', 'toLocation', 'km', 'roundTrip'], payload)
			};

			this.setOriginalKm();
			this.updateKgCO();
		},
		handleCancel(id) {
			this.$bvModal
				.msgBoxConfirm(this.FormMSG(20, 'Please confirm if you want to reject this expense.'), {
					title: this.FormMSG(21, 'Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.FormMSG(22, 'YES'),
					cancelTitle: this.FormMSG(23, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false,
					noCloseOnBackdrop: true
				})
				.then(async (v) => {
					if (!v) return;
					await this.deleteExpense(id);
				})
				.catch((err) => console.log(err))
				.finally(() => {
					this.$emit('mileage-form-mobile:cancelled', true);
					this.goBack();
				});
		},
		convertToKgCo2(kgCo) {
			return !isNil(rendKgCo2(kgCo)) ? rendKgCo2(kgCo).replace(/[\s+kg]/gi, '') : 0;
		},
		convertToCurrency(amount) {
			return !isNil(rendCurrency(amount)) ? rendCurrency(amount).replace(/[\s+€]/gi, '') : 0;
		},
		handleDateInput(e) {
			document.getElementById('mileage-date-picker').click();
		},
		setOriginalKm() {
			if (
				!_.isNil(this.expense.originalKm) &&
				this.expense.originalKm > 0 &&
				this.expense.originalKm !== this.expense.km &&
				this.expense.originalFromLocation === this.expense.fromLocation &&
				this.expense.originalToLocation === this.expense.toLocation
			) {
				this.expense.originalKm = this.expense.km;
				this.expense.km = recalculateDistanceValueByUnit(this.expense.originalKm);
			}
		},
		calculateLoading(payload) {
			this.loading = payload;
		},
		openInfoDistance() {
			this.popInfoDistance = !this.popInfoDistance;
		},
		isInvalidGoogleFrom(payload) {
			this.invalidGoogleFrom = payload;
			this.isInvalid = this.invalidGoogleFrom || this.invalidGoogleTo;
			this.$emit('mileage:invalid', this.isInvalid);
		},
		isInvalidGoogleTo(payload) {
			this.invalidGoogleTo = payload;
			this.isInvalid = this.invalidGoogleFrom || this.invalidGoogleTo;
			this.$emit('mileage:invalid', this.isInvalid);
		}
	},
	validations() {
		return {
			expense: {
				description: {
					required,
					min: minLength(4)
				},
				subCategory: {
					required
				},
				km: {
					required,
					decimal,
					min: greaterThanZero
				}
			}
		};
	},
	watch: {
		'expense.description': function (newVal, oldVal) {
			this.$v.$touch();
		},
		editData: {
			handler(n) {
				if (!isNil(n)) {
					this.expense = n;

					if (this.expense.km === 0) {
						this.expense.km = '';
					}

					if (isNil(this.expense.subCategory)) {
						this.expense = {
							...this.expense,
							subCategory: 0,
							subType: 0,
							additionalSubType: 0
						};
					}

					this.carbonData = n.carbon;
					/**
					 * @TODO
					 * Do not find why this bug (regression), need more investigations
					 * But for now let force validated to 0
					 * When value of action is ADD
					 */
					if (this.action === 0) {
						this.expense.validated = 0;
					}
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
